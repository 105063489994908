// Icon.tsx
// Icons import
import Add from '~/icons/add.svg';
import Alert from '~/icons/alert.svg';
import AndercoreA from '~/icons/andercore-a.svg';
import AndercoreLogoBig from '~/icons/andercore-logo-big.svg';
import AndercoreLogoSmall from '~/icons/andercore-logo-small.svg';
import ArrowLeft from '~/icons/arrow-left.svg';
import Billing from '~/icons/billing.svg';
import BusinessHandshake from '~/icons/business-handshake.svg';
import Cancel from '~/icons/cancel.svg';
import CategoryInverter from '~/icons/category-inverter.svg';
import ChatBubble from '~/icons/chat-bubble-oval-smiley.svg';
import CheckCircle from '~/icons/check-circle.svg';
import Check from '~/icons/check.svg';
import ChevronDown from '~/icons/chevron-down.svg';
import ChevronUp from '~/icons/chevron-up.svg';
import ClaimBilling from '~/icons/claimManagement/billing.svg';
import Calendar from '~/icons/calendar.svg';
import DisputeBubble from '~/icons/dispute-bubble.svg';
import ClaimManagement from '~/icons/claimManagement/claimManagement.svg';
import ClaimDelivery from '~/icons/claimManagement/delivery.svg';
import LastUpdated from '~/icons/claimManagement/lastUpdated.svg';
import MessageRead from '~/icons/claimManagement/messageRead.svg';
import NoComments from '~/icons/claimManagement/noComments.svg';
import Quality from '~/icons/claimManagement/quality.svg';
import BillingInformation from '~/icons/claimManagement/reasons/billing-information.svg';
import LateDelivery from '~/icons/claimManagement/reasons/late-delivery.svg';
import MaterialDefect from '~/icons/claimManagement/reasons/material-defect.svg';
import OtherReason from '~/icons/claimManagement/reasons/other.svg';
import PackagingDamage from '~/icons/claimManagement/reasons/packaging-damage.svg';
import PaidAlready from '~/icons/claimManagement/reasons/paid-already.svg';
import PaymentAmount from '~/icons/claimManagement/reasons/payment-amount.svg'; // eslint-disable-line import/order
import Performance from '~/icons/claimManagement/reasons/performance.svg';
import ProductQuantity from '~/icons/claimManagement/reasons/product-quantity.svg';
import ProductionDate from '~/icons/claimManagement/reasons/production-date.svg';
import SerialNumber from '~/icons/claimManagement/reasons/serial-number.svg';
import ReasonSoftware from '~/icons/claimManagement/reasons/software.svg';
import WrongProducts from '~/icons/claimManagement/reasons/wrong-products.svg';
import WrongQuantity from '~/icons/claimManagement/reasons/wrong-quantity.svg';
import Resolved from '~/icons/claimManagement/resolved.svg';
import Clock from '~/icons/clock.svg';
import CoffeeMug from '~/icons/coffee-mug.svg';
import Coins from '~/icons/coins.svg';
import Collapse from '~/icons/collapse.svg';
import DE from '~/icons/country/de.svg';
import EN from '~/icons/country/en.svg';
import ES from '~/icons/country/es.svg';
import FR from '~/icons/country/fr.svg';
import IT from '~/icons/country/it.svg';
import DeliveryTruck from '~/icons/delivery-truck.svg';
import Delivery from '~/icons/delivery.svg';
import Document from '~/icons/document.svg';
import Download from '~/icons/download.svg';
import Pencil from '~/icons/edit-pencil.svg';
import EmptyBilling from '~/icons/empty-state/billing.svg';
import NoClaims from '~/icons/empty-state/claim.svg';
import EmptyNotFound from '~/icons/empty-state/notfound.svg';
import EmptyOrders from '~/icons/empty-state/orders.svg';
import EmptyQuotes from '~/icons/empty-state/quotes.svg';
import EmptyTrackAndTraceSearch from '~/icons/empty-state/track-n-trace-search.svg';
import EmptyTrackAndTrace from '~/icons/empty-state/track-n-trace.svg';
import Envelope from '~/icons/envelope.svg';
import Expand from '~/icons/expand.svg';
import EyeClosed from '~/icons/eye-closed.svg';
import EyeOpen from '~/icons/eye-open.svg';
import FileAttachement from '~/icons/file-attachement.svg';
import HomeBig from '~/icons/home-big.svg';
import Home from '~/icons/home.svg';
import InvoiceDue from '~/icons/invoice-due.svg';
import Loader from '~/icons/loader.svg';
import Location from '~/icons/location.svg';
import Logout from '~/icons/logout.svg';
import Mail from '~/icons/mail.svg';
import Minus from '~/icons/minus.svg';
import OfficeBuilding from '~/icons/office-building.svg';
import Orders from '~/icons/orders.svg';
import Percent from '~/icons/percent.svg';
import Printer from '~/icons/printer.svg';
import Profile from '~/icons/profile.svg';
import Quotes from '~/icons/quotes.svg';
import Settings from '~/icons/settings.svg';
import Shop from '~/icons/shop.svg';
import Smartphone from '~/icons/smartphone.svg';
import AcPowerRange from '~/icons/store/acPowerRange.svg';
import Batteries from '~/icons/store/batteries.svg';
import BatteryVoltage from '~/icons/store/batteryVoltage.svg';
import Cables from '~/icons/store/cables.svg';
import CableType from '~/icons/store/cableType.svg';
import Capacity from '~/icons/store/capacity.svg';
import CellType from '~/icons/store/cellType.svg';
import Colour from '~/icons/store/colour.svg';
import Container from '~/icons/store/container.svg';
import Delivery2 from '~/icons/store/delivery.svg';
import NoResults from '~/icons/store/filter-no-results.svg';
import Filter from '~/icons/store/filter.svg';
import InverterPower from '~/icons/store/inverterPower.svg';
import Inverters from '~/icons/store/inverters.svg';
import InverterType from '~/icons/store/inverterType.svg';
import Length from '~/icons/store/length.svg';
import MaxDCVoltage from '~/icons/store/maxDCVoltage.svg';
import ModulePower from '~/icons/store/modulePower.svg';
import Modules from '~/icons/store/modules.svg';
import ModuleType from '~/icons/store/moduleType.svg';
import NumberOfPhases from '~/icons/store/numberOfPhases.svg';
import OutputPower from '~/icons/store/outputPower.svg';
import Pallet from '~/icons/store/pallet.svg';
import PanelEfficiency from '~/icons/store/panelEfficiency.svg';
import Search from '~/icons/store/search.svg';
import Storage from '~/icons/store/storage.svg';
import Warranty from '~/icons/store/warranty.svg';
import SupportContact from '~/icons/supportContact.svg';
import Timer from '~/icons/timer.svg';
import TrackAndTrace from '~/icons/track-and-trace.svg';
import CustomerSupport from '~/icons/track-and-trace/customer-support.svg';
import InterfaceCalendar from '~/icons/track-and-trace/interface-calendar.svg';
import LocationOffice from '~/icons/track-and-trace/location-office.svg';
import Phone from '~/icons/track-and-trace/phone.svg';
import ChevronRight from '~/icons/track-and-trace/progress-bar/chevron-right.svg';
import Delivered from '~/icons/track-and-trace/progress-bar/delivered.svg';
import InManufacturing from '~/icons/track-and-trace/progress-bar/in-manufacturing.svg';
import OrderPlaced from '~/icons/track-and-trace/progress-bar/order-placed.svg';
import TransferCart from '~/icons/track-and-trace/progress-bar/transfer-cart.svg';
import Truck from '~/icons/track-and-trace/progress-bar/truck.svg';
import ShippingBox from '~/icons/shipping-box.svg';
import TravelMapLocationPin from '~/icons/travel-map-location-pin.svg';
import Upload from '~/icons/upload.svg';
import Wallet from '~/icons/wallet.svg';
import WhatsApp from '~/icons/whats-app.svg';
import QuoteNew from '~/icons/quote-new.svg';
import ThreeDots from '~/icons/3dots.svg';

// External imports
import { Suspense } from 'react';
import { twMerge } from 'tailwind-merge';
import { ColorModeEnum } from '~/utils/generalUtils';
import Flex from '../customComponents/flex/Flex';
import { colors } from '~/utils/tailwindUtils';

interface IconType {
  [key: string]: React.SVGProps<SVGSVGElement>;
}

/**
 * IconName enum to define all the icons used in the application
 */
export enum IconName {
  Add = 'add',
  Alert = 'alert',
  AndercoreA = 'andercore-a',
  AndercoreLogoBig = 'andercore-logo-big',
  AndercoreLogoSmall = 'andercore-logo-small',
  ArrowLeft = 'arrow-left',
  Billing = 'billing',
  BusinessHandshake = 'business-handshake',
  Cancel = 'cancel',
  CategoryInverter = 'category-inverter',
  ChatBubble = 'chat-bubble',
  CheckCircle = 'check-circle',
  Check = 'check',
  ChevronDown = 'chevron-down',
  ChevronUp = 'chevron-up',
  ClaimBilling = 'claimBilling',
  Calendar = 'calendar',
  ClaimDelivery = 'claimDelivery',
  ClaimManagement = 'claimManagement',
  Dispute = 'dispute',
  LastUpdated = 'lastUpdated',
  MessageRead = 'messageRead',
  NoComments = 'noComments',
  Quality = 'quality',
  BillingInformation = 'billingInformation',
  LateDelivery = 'lateDelivery',
  MaterialDefect = 'materialDefect',
  OtherReason = 'otherReason',
  PackagingDamage = 'packagingDamage',
  PaidAlready = 'paidAlready',
  PaymentAmount = 'paymentAmount',
  Performance = 'performance',
  ProductQuantity = 'productQuantity',
  ProductionDate = 'productionDate',
  SerialNumber = 'serialNumber',
  ReasonSoftware = 'reasonSoftware',
  WrongProducts = 'wrongProducts',
  WrongQuantity = 'wrongQuantity',
  Resolved = 'resolved',
  Clock = 'clock',
  CoffeeMug = 'coffeeMug',
  Coins = 'coins',
  Collapse = 'collapse',
  DE = 'DE',
  EN = 'EN',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
  DeliveryTruck = 'deliveryTruck',
  Delivery = 'delivery',
  Document = 'document',
  Download = 'download',
  Pencil = 'pencil',
  EmptyBilling = 'emptyBilling',
  NoClaims = 'noClaims',
  EmptyNotFound = 'emptyNotFound',
  EmptyOrders = 'emptyOrders',
  EmptyQuotes = 'emptyQuotes',
  EmptyTrackAndTrace = 'emptyTrackAndTrace',
  EmptyTrackAndTraceSearch = 'emptyTrackAndTraceSearch',
  Envelope = 'envelope',
  Expand = 'expand',
  EyeClosed = 'eyeClosed',
  EyeOpen = 'eyeOpen',
  FileAttachement = 'fileAttachement',
  HomeBig = 'homeBig',
  Home = 'home',
  InvoiceDue = 'invoiceDue',
  Loader = 'loader',
  Location = 'location',
  Logout = 'logout',
  Mail = 'mail',
  Minus = 'minus',
  OfficeBuilding = 'officeBuilding',
  Orders = 'orders',
  Percent = 'percent',
  Printer = 'printer',
  Profile = 'profile',
  Quotes = 'quotes',
  Settings = 'settings',
  Shop = 'shop',
  Smartphone = 'smartphone',
  AcPowerRange = 'acPowerRange',
  Batteries = 'batteries',
  BatteryVoltage = 'batteryVoltage',
  Cables = 'cables',
  CableType = 'cableType',
  Capacity = 'capacity',
  CellType = 'cellType',
  Colour = 'colour',
  Container = 'container',
  Delivery2 = 'delivery2',
  NoResults = 'noResults',
  Filter = 'filter',
  InverterPower = 'inverterPower',
  Inverters = 'inverters',
  InverterType = 'inverterType',
  Length = 'length',
  MaxDCVoltage = 'maxDCVoltage',
  ModulePower = 'modulePower',
  Modules = 'modules',
  ModuleType = 'moduleType',
  NumberOfPhases = 'numberOfPhases',
  OutputPower = 'outputPower',
  Pallet = 'pallet',
  PanelEfficiency = 'panelEfficiency',
  Search = 'search',
  Storage = 'storage',
  Warranty = 'warranty',
  SupportContact = 'supportContact',
  Timer = 'timer',
  TrackAndTrace = 'trackAndTrace',
  CustomerSupport = 'customerSupport',
  InterfaceCalendar = 'interfaceCalendar',
  LocationOffice = 'locationOffice',
  Phone = 'phone',
  ChevronRight = 'chevronRight',
  Delivered = 'delivered',
  InManufacturing = 'inManufacturing',
  OrderPlaced = 'orderPlaced',
  TransferCart = 'transferCart',
  Truck = 'truck',
  ShippingBox = 'shippingBox',
  TravelMapLocationPin = 'travelMapLocationPin',
  Upload = 'upload',
  Wallet = 'wallet',
  WhatsApp = 'whatsApp',
  QuoteNew = 'QuoteNew',
  ThreeDots = 'ThreeDots',
}

const iconType: IconType = {
  [IconName.Add]: Add,
  [IconName.Alert]: Alert,
  [IconName.AndercoreA]: AndercoreA,
  [IconName.AndercoreLogoBig]: AndercoreLogoBig,
  [IconName.AndercoreLogoSmall]: AndercoreLogoSmall,
  [IconName.ArrowLeft]: ArrowLeft,
  [IconName.Billing]: Billing,
  [IconName.BusinessHandshake]: BusinessHandshake,
  [IconName.Cancel]: Cancel,
  [IconName.CategoryInverter]: CategoryInverter,
  [IconName.ChatBubble]: ChatBubble,
  [IconName.CheckCircle]: CheckCircle,
  [IconName.Check]: Check,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ChevronUp]: ChevronUp,
  [IconName.ClaimBilling]: ClaimBilling,
  [IconName.Calendar]: Calendar,
  [IconName.ClaimDelivery]: ClaimDelivery,
  [IconName.ClaimManagement]: ClaimManagement,
  [IconName.Dispute]: DisputeBubble,
  [IconName.LastUpdated]: LastUpdated,
  [IconName.MessageRead]: MessageRead,
  [IconName.NoComments]: NoComments,
  [IconName.Quality]: Quality,
  [IconName.BillingInformation]: BillingInformation,
  [IconName.LateDelivery]: LateDelivery,
  [IconName.MaterialDefect]: MaterialDefect,
  [IconName.OtherReason]: OtherReason,
  [IconName.PackagingDamage]: PackagingDamage,
  [IconName.PaidAlready]: PaidAlready,
  [IconName.PaymentAmount]: PaymentAmount,
  [IconName.Performance]: Performance,
  [IconName.ProductQuantity]: ProductQuantity,
  [IconName.ProductionDate]: ProductionDate,
  [IconName.SerialNumber]: SerialNumber,
  [IconName.ReasonSoftware]: ReasonSoftware,
  [IconName.WrongProducts]: WrongProducts,
  [IconName.WrongQuantity]: WrongQuantity,
  [IconName.Resolved]: Resolved,
  [IconName.Clock]: Clock,
  [IconName.CoffeeMug]: CoffeeMug,
  [IconName.Coins]: Coins,
  [IconName.Collapse]: Collapse,
  [IconName.DE]: DE,
  [IconName.EN]: EN,
  [IconName.ES]: ES,
  [IconName.FR]: FR,
  [IconName.IT]: IT,
  [IconName.DeliveryTruck]: DeliveryTruck,
  [IconName.Delivery]: Delivery,
  [IconName.Document]: Document,
  [IconName.Download]: Download,
  [IconName.Pencil]: Pencil,
  [IconName.EmptyBilling]: EmptyBilling,
  [IconName.NoClaims]: NoClaims,
  [IconName.EmptyNotFound]: EmptyNotFound,
  [IconName.EmptyOrders]: EmptyOrders,
  [IconName.EmptyQuotes]: EmptyQuotes,
  [IconName.EmptyTrackAndTrace]: EmptyTrackAndTrace,
  [IconName.EmptyTrackAndTraceSearch]: EmptyTrackAndTraceSearch,
  [IconName.Envelope]: Envelope,
  [IconName.Expand]: Expand,
  [IconName.EyeClosed]: EyeClosed,
  [IconName.EyeOpen]: EyeOpen,
  [IconName.FileAttachement]: FileAttachement,
  [IconName.HomeBig]: HomeBig,
  [IconName.Home]: Home,
  [IconName.InvoiceDue]: InvoiceDue,
  [IconName.Loader]: Loader,
  [IconName.Location]: Location,
  [IconName.Logout]: Logout,
  [IconName.Mail]: Mail,
  [IconName.Minus]: Minus,
  [IconName.OfficeBuilding]: OfficeBuilding,
  [IconName.Orders]: Orders,
  [IconName.Percent]: Percent,
  [IconName.Printer]: Printer,
  [IconName.Profile]: Profile,
  [IconName.Quotes]: Quotes,
  [IconName.Settings]: Settings,
  [IconName.Shop]: Shop,
  [IconName.Smartphone]: Smartphone,
  [IconName.AcPowerRange]: AcPowerRange,
  [IconName.Batteries]: Batteries,
  [IconName.BatteryVoltage]: BatteryVoltage,
  [IconName.Cables]: Cables,
  [IconName.CableType]: CableType,
  [IconName.Capacity]: Capacity,
  [IconName.CellType]: CellType,
  [IconName.Colour]: Colour,
  [IconName.Container]: Container,
  [IconName.Delivery2]: Delivery2,
  [IconName.NoResults]: NoResults,
  [IconName.Filter]: Filter,
  [IconName.InverterPower]: InverterPower,
  [IconName.Inverters]: Inverters,
  [IconName.InverterType]: InverterType,
  [IconName.Length]: Length,
  [IconName.MaxDCVoltage]: MaxDCVoltage,
  [IconName.ModulePower]: ModulePower,
  [IconName.Modules]: Modules,
  [IconName.ModuleType]: ModuleType,
  [IconName.NumberOfPhases]: NumberOfPhases,
  [IconName.OutputPower]: OutputPower,
  [IconName.Pallet]: Pallet,
  [IconName.PanelEfficiency]: PanelEfficiency,
  [IconName.Search]: Search,
  [IconName.Storage]: Storage,
  [IconName.Warranty]: Warranty,
  [IconName.SupportContact]: SupportContact,
  [IconName.Timer]: Timer,
  [IconName.TrackAndTrace]: TrackAndTrace,
  [IconName.CustomerSupport]: CustomerSupport,
  [IconName.InterfaceCalendar]: InterfaceCalendar,
  [IconName.LocationOffice]: LocationOffice,
  [IconName.Phone]: Phone,
  [IconName.ChevronRight]: ChevronRight,
  [IconName.Delivered]: Delivered,
  [IconName.InManufacturing]: InManufacturing,
  [IconName.OrderPlaced]: OrderPlaced,
  [IconName.TransferCart]: TransferCart,
  [IconName.Truck]: Truck,
  [IconName.ShippingBox]: ShippingBox,
  [IconName.TravelMapLocationPin]: TravelMapLocationPin,
  [IconName.Upload]: Upload,
  [IconName.Wallet]: Wallet,
  [IconName.WhatsApp]: WhatsApp,
  [IconName.QuoteNew]: QuoteNew,
  [IconName.ThreeDots]: ThreeDots,
};

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  size?: number;
  width?: number;
  height?: number;
  hovered?: boolean;
  color?: string;
  colorMode?: 'fill' | 'stroke';
  customStrokeWidth?: number;
}

/**
 * Icon component to render SVG icons
 * - name: Ex: IconsName.Home
 * - size: 24 default
 * - width: default undefined so size will be added to width, if defined it will override size
 * - height: default undefined so size will be added to height, if defined it will override size
 * - hovered: boolean to change color on hover
 * - color: default #3B3F44
 * - colorMode: 'fill' | 'stroke' default 'stroke'
 * - customStrokeWidth: number to override default stroke width of 1
 * - className: string to add custom classes to Flex component surrounding the icon
 * - ...restProps: any other props to be passed to the Flex component
 */
export const Icon = ({
  name,
  size = 24,
  width,
  height,
  hovered,
  color = colors.primary,
  colorMode = ColorModeEnum.Stroke,
  customStrokeWidth,
  className,
  ...restProps
}: IconProps) => {
  let Icon = (iconType[name] || Home) as React.FC<
    React.SVGProps<SVGSVGElement>
  >;

  const strokeWidth = colorMode === ColorModeEnum.Stroke ? 1 : 0;
  const fill = colorMode === ColorModeEnum.Fill ? color : 'none';

  return (
    <Flex
      className={twMerge('justify-center items-center', className)}
      {...restProps}
    >
      <Suspense fallback={<></>}>
        <Icon
          data-testid="icon"
          stroke={hovered ? colors.red[600] : color}
          height={height ?? size}
          width={width ?? size}
          strokeWidth={customStrokeWidth || strokeWidth}
          fill={fill}
        />
      </Suspense>
    </Flex>
  );
};
