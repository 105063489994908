import { EMAIL_REGEX } from '~/constants/regex';
import { formatDate } from './dateUtils';

/**
 * Retrieves the title of the current document.
 *
 * @return {string} The title of the document, or an empty string if the document does not have a title.
 */
export const getDocumentTitle = () => {
  if (typeof window !== 'undefined') {
    return document.getElementsByTagName('title')?.[0]?.innerHTML ?? '';
  }
  return '';
};

export const mergeMetaTagContent = (pageName: string, detailName?: string) => {
  return detailName ? detailName + ' | ' + pageName : pageName;
};

export const groupByWithOrder = <
  TKey extends keyof any,
  TElement extends Record<TKey, any>
>(
  array: TElement[],
  key: TKey
) => {
  const grouped = array.reduce((result, item) => {
    const groupKey = item[key];
    const group = result[groupKey] || [];
    group.push(item);
    result[groupKey] = group;
    return result;
  }, {} as Record<string, any[]>);

  const uniqueOrderedKeys = Array.from(new Set(array.map((item) => item[key])));
  return uniqueOrderedKeys.map((key) => [key, grouped[key]]);
};

export const isNil = (value: unknown) => value == null;

export const findKeyByValue = (
  obj: { [key: string]: string },
  value: string
) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const formatDecimalString = (inputString: string, locale = 'en-EN') => {
  const decimalNumber = parseFloat(inputString);

  if (isNaN(decimalNumber)) {
    return '';
  }

  if (decimalNumber % 1 === 0) {
    return decimalNumber.toFixed(0);
  } else {
    return decimalNumber.toLocaleString(locale);
  }
};

/**
 * Checks if the given email is associated with an employee.
 *
 * @param {string} email - The email to check.
 * @return {boolean} Returns true if the email is associated with an employee, false otherwise.
 */
export const checkUserIsEmployee = (email: string) => {
  const whiteList = [
    'aptstack@gmail.com',
    'andercoreexternal@gmail.com',
    'ka.majchrowska@gmail.com',
    'hello@chrissytopal.com',
  ];

  return (
    email?.endsWith('@andercore.com') ||
    whiteList.some((wlEmail) => wlEmail === email)
  );
};

/**
 * Format an amount based on the specified currency and locale.
 * @param amount - The amount to be formatted.
 * @param currency - The currency code (e.g., 'EUR', 'USD').
 * @param locale - The locale code (e.g., 'de', 'en').
 * @returns The formatted currency string.
 */
export const localizeAmountWithCurrency = (
  amount: number = 0.0,
  currency: string = 'EUR',
  locale: string = 'de'
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return Math.round((bytes / Math.pow(k, i)) * 100) / 100 + ' ' + sizes[i];
};

export const countNewlines = (text: string) => {
  let count = 0;
  let index = text.indexOf('\n');
  while (index !== -1) {
    count++;
    index = text.indexOf('\n', index + 1);
  }
  return count;
};

export const cleanStyles = (messageBody: string): string => {
  let htmlString = messageBody;
  let tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  let elementsWithStyle = tempDiv.querySelectorAll('[style]');
  elementsWithStyle.forEach((element) => {
    // @ts-ignore
    element.style.cssText = '';
  });

  let plainHtml = tempDiv.innerHTML;

  return plainHtml;
};

export const createFileName = (
  entityType: string,
  selectedFormat: string
): string => {
  const dateStr = formatDate(new Date());
  return `Andercore_${entityType}_${dateStr}.${selectedFormat}`;
};

export const validateEmail = (email: string) => {
  return String(email).toLowerCase().match(EMAIL_REGEX);
};

// Function to deeply merge two objects
export const deepMerge = (target: any, source: any) => {
  for (const key in source) {
    if (source[key] instanceof Object) {
      target[key] = deepMerge(target[key] || {}, source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};

export enum ColorModeEnum {
  Stroke = 'stroke',
  Fill = 'fill',
}

/**
 * Calculate the difference in milliseconds between a future date and the current date
 * @param {string | Date} futureDate - The future date and time
 * @returns {number} - The difference in milliseconds
 */
export function getTimeDifferenceInMilliseconds(
  futureDate: string | Date
): number {
  if (!futureDate) return 0;

  // Ensure the future date is a valid Date object
  const future: Date = new Date(futureDate);

  if (isNaN(future.getTime())) {
    return 0;
  }

  // NOTE: Following is necessary - Set the future date to 23:59 CET (Central European Time)
  future.setUTCHours(21, 59, 0, 0); // 21:59 UTC is 23:59 CET (Central European Time)

  const now: Date = new Date();
  const expiryDate = future.getTime();
  const currentDate = now.getTime();

  return expiryDate > currentDate ? expiryDate - currentDate : 0;
}

/**
 * Converts milliseconds to days, hours, minutes and seconds
 * @param milliseconds
 * @returns
 */
export const getDaysHoureMinutesSeconds = (
  milliseconds: number,
  d: string,
  h: string,
  m: string,
  s: string
) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const daysStr = `${days}${d} `;
  const hoursStr = `${hours}${h} `;
  const minutesStr = `${minutes}${m} `;
  const secondsStr = `${seconds}${s}`;

  if (days > 0) {
    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`;
  } else if (hours > 0) {
    return `${hoursStr}${minutesStr}${secondsStr}`;
  } else if (minutes > 0) {
    return `${minutesStr}${secondsStr}`;
  } else {
    return `${secondsStr}`;
  }
};
